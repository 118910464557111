import {AlivePresence, AlivePresenceData, PresenceItem, isPresenceChannel} from './alive-presence'
import {SubscriptionSet, Topic} from './subscription-set'
import type {Socket} from '@github/stable-socket'
import {StableSocket} from '@github/stable-socket'
import type {Subscription} from './subscription-set'
import {eachSlice} from './iterables'
import {retry} from './eventloop-tasks'

interface AliveMessageData {
  timestamp: number
  wait: number
  gid?: string
}

export type AliveData = AliveMessageData | AlivePresenceData

interface Ack {
  e: 'ack'
  off: string
  health: boolean
}

interface Message {
  e: 'msg'
  ch: string
  off: string
  data: AliveData
}

interface MessageEvent {
  type: 'message'
  data: AliveMessageData
}

interface PresenceEvent {
  type: 'presence'
  data: PresenceItem[]
}

export type AliveEvent = {channel: string} & (MessageEvent | PresenceEvent)
export type Notifier<T> = (subscribers: Iterable<T>, event: AliveEvent) => void

function generatePresenceId() {
  // outputs a string like 2118047710_1628653223
  return `${Math.round(Math.random() * (Math.pow(2, 31) - 1))}_${Math.round(Date.now() / 1000)}`
}

export default class AliveSession<T> {
  private socket: Socket
  private subscriptions = new SubscriptionSet<T>()
  private notify: Notifier<T>
  private refreshUrl: string
  private shared: boolean
  private state: 'online' | 'offline' = 'online'
  private redeployEarlyReconnectTimeout: ReturnType<typeof setTimeout> | undefined
  private retrying: AbortController | null = null
  private readonly presenceId = generatePresenceId()
  private connectionCount = 0
  private presence = new AlivePresence()

  constructor(private url: string, refreshUrl: string, shared: boolean, notify: Notifier<T>) {
    this.refreshUrl = refreshUrl
    this.notify = notify
    this.shared = shared
    this.socket = this.connect()
  }

  subscribe(subscriptions: Array<Subscription<T>>) {
    const added = this.subscriptions.add(...subscriptions)
    this.sendSubscribe(added)

    // Send locally cached presence items to presence channels.
    // This is necessary because we only receive a full presence state when we initially subscribe to a presence channel.
    // If a second subscription is addded to the same presence channel, it will never receive another full state from Alive.
    for (const subscription of subscriptions) {
      const channel = subscription.topic.name
      if (!isPresenceChannel(channel)) {
        continue
      }

      this.notifyCachedPresence(subscription.subscriber, channel)
    }
  }

  unsubscribe(subscriptions: Array<Subscription<T>>) {
    const removed = this.subscriptions.delete(...subscriptions)
    this.sendUnsubscribe(removed)
  }

  unsubscribeAll(...subscribers: T[]) {
    const removed = this.subscriptions.drain(...subscribers)
    this.sendUnsubscribe(removed)
  }

  requestPresence(subscriber: T, channels: string[]) {
    // This is used for SharedWorker to send presence items on new subscriptions.
    // This will only be called when the tab already has a subscription to the presence channel and receives another (redundant) subscription
    for (const channel of channels) {
      this.notifyCachedPresence(subscriber, channel)
    }
  }

  private notifyCachedPresence(subscriber: T, channel: string) {
    const presenceItems = this.presence.getChannelItems(channel)
    if (presenceItems.length === 0) {
      return
    }

    // Presence items exist for this channel, send them to the subscriber immediately.
    this.notify([subscriber], {channel, type: 'presence', data: presenceItems})
  }

  online() {
    this.state = 'online'
    this.retrying?.abort()
    this.socket.open()
  }

  offline() {
    this.state = 'offline'
    this.retrying?.abort()
    this.socket.close()
  }

  shutdown() {
    if (this.shared) {
      self.close()
    }
  }

  socketDidOpen() {
    this.connectionCount++
    // force a new url into the socket so that the server can pick up the new presence id
    // TODO: we should add a method to StableSocket to allow url to be updated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(this.socket as any).url = this.getUrlWithPresenceId()

    // Subscribe again after connection failure.
    this.sendSubscribe(this.subscriptions.topics())
  }

  socketDidClose(socket: Socket, code: number, reason: string) {
    if (this.redeployEarlyReconnectTimeout !== undefined) {
      clearTimeout(this.redeployEarlyReconnectTimeout)
    }

    if (reason === 'Alive Redeploy') {
      // When Alive re-deploys, it will drain the existing connections over a 3 minute period.
      // Alive also forces clients clients to reconnect every 25-35 minutes by disconnecting from the server side.
      // After a deploy, this ~30 minute reconnect will cause big ripples in the number of connections.
      // To avoid those ripples, the client should manually reconnect after 3-25 minutes after a deploy.
      const reconnectDelayMinutes = 3 + Math.random() * 22 // 3-25 minutes
      const reconnectDelay = reconnectDelayMinutes * 60 * 1000

      this.redeployEarlyReconnectTimeout = setTimeout(() => {
        // Send the same code back to the server so that it knows this is a reconnect due to a deploy.
        // The Alive will keep gathering messages for the currently subscribed channels, so that we can do a catchup after reconnect.
        // eslint-disable-next-line i18n-text/no-en
        this.socket.close(1000, 'Alive Redeploy Early Client Reconnect')
      }, reconnectDelay)
    }
  }

  socketDidFinish() {
    if (this.state === 'offline') return
    this.reconnect()
  }

  socketDidReceiveMessage(_: Socket, message: string) {
    const payload = JSON.parse(message)
    switch (payload.e) {
      case 'ack': {
        this.handleAck(payload)
        break
      }
      case 'msg': {
        this.handleMessage(payload)
        break
      }
    }
  }

  private handleAck(ack: Ack) {
    for (const topic of this.subscriptions.topics()) {
      topic.offset = ack.off
    }
  }

  private handleMessage(msg: Message) {
    const channel = msg.ch
    const topic = this.subscriptions.topic(channel)
    if (!topic) return
    topic.offset = msg.off

    if ('e' in msg.data) {
      // This is a presence message
      const presenceItems = this.presence.handleMessage(channel, msg.data)

      this.notify(this.subscriptions.subscribers(channel), {
        channel,
        type: 'presence',
        data: presenceItems
      })
      return
    }

    if (!msg.data.wait) msg.data.wait = 0
    this.notify(this.subscriptions.subscribers(channel), {
      channel,
      type: 'message',
      data: msg.data
    })
  }

  private async reconnect() {
    if (this.retrying) return
    try {
      this.retrying = new AbortController()
      const fn = () => fetchRefreshUrl(this.refreshUrl)
      const url = await retry(fn, Infinity, 60000, this.retrying.signal)
      if (url) {
        this.url = url
        this.socket = this.connect()
      } else {
        this.shutdown()
      }
    } catch (e) {
      if (e.name !== 'AbortError') throw e
    } finally {
      this.retrying = null
    }
  }

  private getUrlWithPresenceId() {
    const liveUrl = new URL(this.url, self.location.origin)
    liveUrl.searchParams.set('shared', this.shared.toString())
    liveUrl.searchParams.set('p', `${this.presenceId}.${this.connectionCount}`)
    return liveUrl.toString()
  }

  private connect(): Socket {
    const socket = new StableSocket(this.getUrlWithPresenceId(), this, {timeout: 4000, attempts: 7})
    socket.open()
    return socket
  }

  private sendSubscribe(topics: Iterable<Topic>) {
    const entries = Array.from(topics, t => [t.signed, t.offset])
    for (const slice of eachSlice(entries, 25)) {
      this.socket.send(JSON.stringify({subscribe: Object.fromEntries(slice)}))
    }
  }

  private sendUnsubscribe(topics: Iterable<Topic>) {
    const signed = Array.from(topics, t => t.signed)
    for (const slice of eachSlice(signed, 25)) {
      this.socket.send(JSON.stringify({unsubscribe: slice}))
    }

    // Clear cached presence items for unsubscribed channels
    for (const topic of topics) {
      if (isPresenceChannel(topic.name)) {
        this.presence.clearChannel(topic.name)
      }
    }
  }
}

type PostUrl = {url?: string; token?: string}
async function fetchRefreshUrl(url: string): Promise<string | null> {
  const data = await fetchJSON<PostUrl>(url)
  return data && data.url && data.token ? post(data.url, data.token) : null
}

async function fetchJSON<T>(url: string): Promise<T | null> {
  const response = await fetch(url, {headers: {Accept: 'application/json'}})
  if (response.ok) {
    return response.json()
  } else if (response.status === 404) {
    return null
  } else {
    throw new Error('fetch error')
  }
}

async function post(url: string, csrf: string): Promise<string> {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'same-origin',
    headers: {
      'Scoped-CSRF-Token': csrf
    }
  })
  if (response.ok) {
    return response.text()
  } else {
    throw new Error('fetch error')
  }
}
