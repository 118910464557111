import type {AliveEvent, Notifier} from '../alive-session'
import {SubscriptionSet, Topic} from '../subscription-set'
import AliveSession from '../alive-session'
import type {Subscription} from '../subscription-set'
import {isPresenceChannel} from '../alive-presence'
import {observe} from 'selector-observer'
import {ready} from '../document-ready'
import safeStorage from '../safe-storage'
import {taskQueue} from '../eventloop-tasks'

function isSharedWorkerSupported(): boolean {
  return 'SharedWorker' in window && safeStorage('localStorage').getItem('bypassSharedWorker') !== 'true'
}

function workerSrc(): string | null {
  return document.head.querySelector<HTMLLinkElement>('link[rel=shared-web-socket-src]')?.href ?? null
}

function socketUrl(): string | null {
  return document.head.querySelector<HTMLLinkElement>('link[rel=shared-web-socket]')?.href ?? null
}

function socketRefreshUrl(): string | null {
  return (
    document.head.querySelector<HTMLLinkElement>('link[rel=shared-web-socket]')?.getAttribute('data-refresh-url') ??
    null
  )
}

function sessionIdentifier(): string | null {
  return (
    document.head.querySelector<HTMLLinkElement>('link[rel=shared-web-socket]')?.getAttribute('data-session-id') ?? null
  )
}

function subscriptions(el: Element): Array<Subscription<Element>> {
  return channels(el).map((topic: Topic) => ({subscriber: el, topic}))
}

export function channels(el: Element): Topic[] {
  const names = (el.getAttribute('data-channel') || '').trim().split(/\s+/)
  return names.map(Topic.parse).filter(isPresent)
}

function isPresent(value: Topic | null): value is Topic {
  return value != null
}

function notify(subscribers: Iterable<Element>, {channel, type, data}: AliveEvent) {
  for (const el of subscribers) {
    el.dispatchEvent(
      new CustomEvent(`socket:${type}`, {
        bubbles: false,
        cancelable: false,
        detail: {name: channel, data}
      })
    )
  }
}

class AliveSessionProxy {
  private worker: SharedWorker
  private subscriptions = new SubscriptionSet<Element>()
  private notify: Notifier<Element>

  constructor(src: string, url: string, refreshUrl: string, sessionId: string, notifier: Notifier<Element>) {
    this.notify = notifier
    this.worker = new SharedWorker(src, `github-socket-worker-v2-${sessionId}`)
    this.worker.port.onmessage = ({data}) => this.receive(data)
    this.worker.port.postMessage({connect: {url, refreshUrl}})
  }

  subscribe(subs: Array<Subscription<Element>>) {
    const added = this.subscriptions.add(...subs)
    if (added.length) {
      this.worker.port.postMessage({subscribe: added})
    }

    // We may want be adding a subscription to a presence channel wich is already subscribed.
    // In this case, we need to explicitly ask the SharedWorker to send us the presence data.
    const addedChannels = new Set(added.map(topic => topic.name))
    const redundantPresenceChannels = subs.reduce((redundantChannels, subscription) => {
      const channel = subscription.topic.name

      if (isPresenceChannel(channel) && !addedChannels.has(channel)) {
        redundantChannels.add(channel)
      }

      return redundantChannels
    }, new Set<string>())

    if (redundantPresenceChannels.size) {
      this.worker.port.postMessage({requestPresence: Array.from(redundantPresenceChannels)})
    }
  }

  unsubscribeAll(...subscribers: Element[]) {
    const removed = this.subscriptions.drain(...subscribers)
    if (removed.length) {
      this.worker.port.postMessage({unsubscribe: removed})
    }
  }

  online() {
    this.worker.port.postMessage({online: true})
  }

  offline() {
    this.worker.port.postMessage({online: false})
  }

  hangup() {
    this.worker.port.postMessage({hangup: true})
  }

  private receive(event: AliveEvent) {
    this.notify(this.subscriptions.subscribers(event.channel), event)
  }
}

function connect() {
  const src = workerSrc()
  if (!src) return

  const url = socketUrl()
  if (!url) return

  const refreshUrl = socketRefreshUrl()
  if (!refreshUrl) return

  const sessionId = sessionIdentifier()
  if (!sessionId) return

  const createSession = () => {
    if (isSharedWorkerSupported()) {
      try {
        return new AliveSessionProxy(src, url, refreshUrl, sessionId, notify)
      } catch (_) {
        // ignore errors.  CSP will some times block SharedWorker creation. Fall back to standard AliveSession.
      }
    }

    return new AliveSession(url, refreshUrl, false, notify)
  }
  const session = createSession()

  type Subs = Array<Subscription<Element>>
  const queueSubscribe = taskQueue<Subs>(subs => session.subscribe(subs.flat()))
  const queueUnsubscribe = taskQueue<Element>(els => session.unsubscribeAll(...els))

  observe('.js-socket-channel[data-channel]', {
    add: el => queueSubscribe(subscriptions(el)),
    remove: el => queueUnsubscribe(el)
  })

  window.addEventListener('online', () => session.online())
  window.addEventListener('offline', () => session.offline())
  window.addEventListener('unload', () => {
    if ('hangup' in session) session.hangup()
  })
}

;(async () => {
  await ready
  connect()
})()
