import getCaretCoordinates, {CaretCoordinates} from 'textarea-caret'

type CaretCache = Map<number, CaretCoordinates>
const inputCache = new WeakMap<HTMLElement, CaretCache>()

/**
 * Get the cached x/y coordinates for the given caret index and textarea.
 */
function fetchCaretCoords(textArea: HTMLTextAreaElement, caretIndex: number): CaretCoordinates {
  let caretCache
  if (inputCache.has(textArea)) {
    caretCache = inputCache.get(textArea)!
  } else {
    caretCache = new Map()
    inputCache.set(textArea, caretCache)
  }

  if (caretCache.has(caretIndex)) {
    return caretCache.get(caretIndex)
  } else {
    const coords = getCaretCoordinates(textArea, caretIndex)
    caretCache.set(caretIndex, coords)
    return coords
  }
}

/**
 * This function recursively searches for the caret index that corresponds to the given coordinates.
 * We keep track of the number of iterations purely for logging to the console during
 * this PR.
 */
const binaryCursorSearch = (
  textArea: HTMLTextAreaElement,
  lower: number,
  upper: number,
  x: number,
  y: number,
  iterations: number
): number => {
  if (upper === lower) {
    return upper
  }

  const mid = Math.floor((upper + lower) / 2)
  if (mid === lower || mid === upper) {
    return mid
  }
  const coords = fetchCaretCoords(textArea, mid)
  const midLine = Math.floor(coords.top / coords.height)
  const cursorLine = Math.floor(y / coords.height)

  if (midLine < cursorLine) {
    return binaryCursorSearch(textArea, mid + 1, upper, x, y, iterations + 1)
  }

  if (midLine > cursorLine) {
    return binaryCursorSearch(textArea, lower, mid - 1, x, y, iterations + 1)
  }

  // if mid is on the same line as the cursor, we need to check the x position
  if (coords.left < x) {
    return binaryCursorSearch(textArea, mid + 1, upper, x, y, iterations + 1)
  }

  if (coords.left > x) {
    return binaryCursorSearch(textArea, lower, mid - 1, x, y, iterations + 1)
  }

  return mid
}

/**
 * The driver function for the binary search.
 */
const findCursorPosition = (textArea: HTMLTextAreaElement, x: number, y: number): number => {
  const startIndex = 0
  const endIndex = textArea.value.length - 1
  const iterations = 0
  return binaryCursorSearch(textArea, startIndex, endIndex, x, y, iterations)
}

function setCursorPosition(textarea: HTMLTextAreaElement, x: number, y: number) {
  const newPosition = findCursorPosition(textarea, x, y)

  textarea.setSelectionRange(newPosition, newPosition)
}

/**
 * Sets the caret in a textarea based on a DragEvent within that textarea.
 * Unfortunately there's no html api to do that directly (x/y -> caret). This function uses an iterative
 * approach to find the caret position from the x/y coordinates of the drag event.
 *
 * Under the hood we use the [textarea-caret npm package](https://www.npmjs.com/package/textarea-caret)
 * to find the x/y coordinates of the caret based on the caret index, and feed those results into a binary search.
 *
 * We use a cache to speed up the search, which is reinitialized when the user drags over the textarea for the first time.
 */
export function updateCaret(textArea: HTMLTextAreaElement, dragEvent: DragEvent) {
  const rect = textArea.getBoundingClientRect()
  // We want to clear the cache when the user first drags an attachment into the textarea.
  // It's better to have to recalculate too much than to have a stale cache with wrong results.
  if (dragEvent.type === 'dragenter') {
    inputCache.delete(textArea)
  }

  const x = dragEvent.clientX - rect.left
  const y = dragEvent.clientY - rect.top + textArea.scrollTop
  setCursorPosition(textArea, x, y)
}
