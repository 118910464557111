// Hotkey Behavior
//
// See https://github.com/github/hotkey

import {install, uninstall} from '@github/hotkey'
import {keyboardShortcutsEnabled} from './keyboard-shortcuts-helper'
import {observe} from 'selector-observer'

// Enable ALL keyboard shortcut based on user preference
if (keyboardShortcutsEnabled()) {
  observe('[data-hotkey]', {
    constructor: HTMLElement,
    add(element) {
      install(element)
    },
    remove(element) {
      uninstall(element)
    }
  })
}
